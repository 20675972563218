<template>
  <div>
    <v-app-bar app dense fixed style="top: 0" flat>
      <v-icon @click="back()" style="font-size: 40px; margin-right: 10px"
        >mdi-chevron-left</v-icon
      >
      <v-title class="d-flex">
        <v-avatar color="grey darken-1" style="margin-right: 15px" size="44"
          ><img :src="avatar" alt="Avatar" srcset="" width="44"
        /></v-avatar>
        <div>
          <h5>Pet magia 3</h5>
          <h6>Online a 10 min</h6>
        </div>
      </v-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>Denunciar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-container style="max-width: 900px; padding-bottom: 69px" class="mt-2">
      <v-row>
        <v-col cols="12" v-for="(item, i) in messages" :key="i">
          <v-alert
            :color="item.to == iam ? 'green' : 'blue-grey'"
            :style="{
              width: '50%',
              float: item.to == iam ? 'right' : 'left',
            }"
            dark
            dense
            prominent
          >
            {{ item.text }}
          </v-alert>
          <h6
            class="message-data"
            :class="item.to == iam ? 'text-right' : 'text-left'"
            style="width: 100%; float: left"
          >
            {{ item.time }}
          </h6>
        </v-col>
      </v-row>
      <div class="bottom"></div>
    </v-container>
    <v-footer dark shift fixed>
      <v-text-field
        v-model="message"
        clearable
        flat
        solo
        hide-details
        prepend-inner-icon="mdi-send"
        label="Send"
      ></v-text-field>

      <v-btn icon @click="sendMessage()">
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-footer>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      avatar: require("@/assets/avatar.jpg"),
      iam: 1,
      message: null,
      messages: [
        {
          to: 1,
          from: 2,
          text: "Olá Mundo",
          time: "11/04/21 14:40",
        },
        {
          to: 2,
          from: 1,
          text: "Olá Dev",
          time: "11/04/21 14:41",
        },
        {
          to: 1,
          from: 2,
          text: "Olá Mundo",
          time: "11/04/21 14:40",
        },
        {
          to: 2,
          from: 1,
          text: "Olá Dev",
          time: "11/04/21 14:41",
        },
        {
          to: 2,
          from: 1,
          text: "Olá Dev",
          time: "11/04/21 14:41",
        },
        {
          to: 2,
          from: 1,
          text: "Olá Dev",
          time: "11/04/21 14:41",
        },
      ],
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async sendMessage() {
      if (this.message) {
        await this.messages.push({
          to: 1,
          from: 2,
          text: this.message,
          time: this.formatDate(Date.now()),
        });
      }

      this.message = null;
      this.scrollToBottom();
    },
    formatDate: (date) => (date ? moment(date).format("DD/MM/YYYY h:mm") : ""),
    scrollToBottom() {
      if (this.messages.length) {
        document
          .querySelector(".bottom")
          .scrollIntoView();
      }
    },
  },
};
</script>